import { Dispatch as ReactDispatch, SetStateAction } from "react";
import { UserDetails } from "@tvg/ts-types/User";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import formatCurrency from "@tvg/formatter/currency";
import { ErrorMessage } from "../../../../types";

export const handleWithdrawRetry =
  (
    errorMessage: ErrorMessage,
    userDetails: UserDetails,
    setErrorMessage: ReactDispatch<SetStateAction<ErrorMessage>>
  ) =>
  () => {
    if (errorMessage.retryCount <= 2) {
      setErrorMessage((prevState) => ({
        ...prevState,
        retryCount: prevState.retryCount + 1,
        isOpen: false,
        isRetry: true
      }));
    } else {
      // Contact Customer Service
      openLiveChat(userDetails);
    }
  };

export const handleWithdrawCloseError =
  (setErrorMessage: ReactDispatch<SetStateAction<ErrorMessage>>) => () => {
    setErrorMessage((prevState) => ({
      ...prevState,
      isOpen: false,
      isRetry: false
    }));
  };

export const insufficientFundsErrorMessage = ({
  minWithdrawAmount,
  userBalance,
  value
}: {
  minWithdrawAmount: string;
  userBalance: number;
  value: string;
}) =>
  +value < +minWithdrawAmount
    ? `${formatCurrency(+minWithdrawAmount)} minimum`
    : `Insufficient funds(${formatCurrency(userBalance)})`;
