import { Dispatch, SetStateAction } from "react";
import { NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import { UserProps } from "@tvg/salesforce/src/types";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import formatCurrency from "@tvg/formatter/currency";
import { Dispatch as ReduxDispatch } from "@reduxjs/toolkit";
import { History } from "@tvg/ts-types/History";
import { get } from "lodash";
import {
  ButtonAction,
  CreditDebitCardCreation,
  ErrorMessage,
  PawsContent,
  PawsLimits,
  PaymentTypeItem,
  PaymentTypeOption
} from "../../../types";
import {
  Action,
  ACTION_AMOUNT,
  ACTION_CARD_CVV,
  ACTION_CARD_EXP,
  ACTION_CARD_NUM,
  ACTION_IS_CVV_INFO_OPEN,
  ACTION_IS_FEE_INFO_OPEN,
  ACTION_RESET_STATE,
  ACTION_RESET_STATE_WITHOUT_AMOUNT,
  ActiveFieldState,
  CardFieldType,
  FeeValueGetter,
  FormDispatch
} from "./types";
import { getFieldFormatters, getFieldValidators } from "./schemaValidations";
import {
  redirectOnFeeFree,
  getLastUsedPMByPaymentType,
  isDesktop
} from "../../../utils";

export const getState =
  (
    formDispatch: FormDispatch,
    accountId: string,
    min: number,
    max: number,
    amount: string,
    selectedField: string,
    cardNum: string,
    cardExp: string,
    cardCVV: string,
    getFeeValue: FeeValueGetter
  ): UnaryFn<void, ActiveFieldState> =>
  () => {
    const defaulState = {
      setState: (value: string) => {
        formDispatch({ type: ACTION_AMOUNT, payload: { value } });
        getFeeValue(accountId, value, min, max);
      },
      value: amount,
      validator: getFieldValidators("amount"),
      formatter: getFieldFormatters("amount")
    };

    switch (selectedField) {
      case "number-field":
        return {
          value: cardNum,
          setState: (value: string) =>
            formDispatch({ type: ACTION_CARD_NUM, payload: { value } }),
          validator: getFieldValidators("number"),
          formatter: getFieldFormatters("number")
        };
      case "expire-field":
        return {
          value: cardExp,
          setState: (value: string) =>
            formDispatch({ type: ACTION_CARD_EXP, payload: { value } }),
          validator: getFieldValidators("expire"),
          formatter: getFieldFormatters("expire")
        };
      case "cvv-field":
        return {
          value: cardCVV,
          setState: (value: string) =>
            formDispatch({ type: ACTION_CARD_CVV, payload: { value } }),
          validator: getFieldValidators("cvv")
        };
      default:
        return defaulState;
    }
  };

export const handleAmountFieldSelection =
  (
    setSelectedField: Dispatch<SetStateAction<string>>,
    addVisitedField: UnaryFn<string, void>
  ) =>
  () => {
    setSelectedField((selected) => {
      addVisitedField(selected);
      return "amount-field";
    });
  };

export const desktopEditCardField = (
  nextValue: string,
  prevValue: string,
  cardAction: CardFieldType,
  callback: (arg1: string, arg2: string) => string,
  isLoading: boolean,
  formDispatch: UnaryFn<Action, void>
) => {
  if (isLoading) {
    return;
  }
  const formattedText = callback(nextValue, prevValue);
  formDispatch({
    type: cardAction,
    payload: { value: formattedText }
  });
};

export const onClearValue =
  (selectedField: string, activeFieldState: ActiveFieldState) => () => {
    const { setState, value } = activeFieldState;
    const removeDigits =
      selectedField !== "amount-field" && /\d[\s/]$/.test(value) ? 2 : 1;
    const nextValue = value.slice(0, -removeDigits);
    setState(nextValue);
  };

export const onAddPeriod =
  (formDispatch: FormDispatch, amount: string, selectedField: string) => () => {
    // Dont let user insert dots as the first value or more then one dot
    if (!amount.includes(".") && selectedField === "amount-field") {
      formDispatch({
        type: ACTION_AMOUNT,
        payload: { value: amount ? amount + "." : "0." }
      });
    }
  };

export const handleCloseError =
  (setErrorMessage: Dispatch<SetStateAction<ErrorMessage>>) => () => {
    setErrorMessage((prevState) => ({
      ...prevState,
      isOpen: false,
      isRetry: false
    }));
  };

export const handleRetry =
  (
    setErrorMessage: UnaryFn<React.SetStateAction<ErrorMessage>, void>,
    errorMessage: ErrorMessage,
    userDetails: UserProps
  ) =>
  () => {
    if (errorMessage.retryCount <= 2) {
      setErrorMessage((prevState) => ({
        ...prevState,
        retryCount: prevState.retryCount + 1,
        isOpen: false,
        isRetry: true
      }));
    } else {
      openLiveChat(userDetails);
    }
  };

export const resetAllFields = (
  resetAll: boolean = false,
  setAddressConfirmationState: Dispatch<SetStateAction<boolean>>,
  setSelectedField: Dispatch<SetStateAction<string>>,
  setVisitedFields: Dispatch<SetStateAction<Set<string>>>,
  formDispatch: FormDispatch,
  setErrorMessage: Dispatch<SetStateAction<ErrorMessage>>,
  defaultErrorMessage: ErrorMessage
) => {
  setAddressConfirmationState(false);
  setSelectedField("amount-field");
  setVisitedFields(new Set<string>());
  setErrorMessage(defaultErrorMessage);
  formDispatch({
    type: resetAll ? ACTION_RESET_STATE : ACTION_RESET_STATE_WITHOUT_AMOUNT
  });
};

export const addVisitedField =
  (setVisitedFields: Dispatch<SetStateAction<Set<string>>>) =>
  (field: string) =>
    setVisitedFields((state) => new Set([...state, field]));

export const removeVisitedField =
  (setVisitedFields: Dispatch<SetStateAction<Set<string>>>) =>
  (field: string) =>
    setVisitedFields(
      (state) => new Set([...state].filter((txt) => txt !== field))
    );

export const getMinValue = (limits: PawsLimits, minLimit: number): number =>
  get(limits, "CCP.deposit.min", 0) > minLimit
    ? (get(limits, "CCP.deposit.min", 0) as number)
    : minLimit;

export const getMaxValue = (limits: PawsLimits, maxLimit: number): number =>
  get(limits, "CCP.deposit.max", 10000000) < maxLimit
    ? (get(limits, "CCP.deposit.max", 10000000) as number)
    : maxLimit;

export const getSuccessMessageActions = (
  onCloseCallback: NullaryFn<void>
): ButtonAction[] => [
  {
    text: "Close",
    onClick: onCloseCallback,
    isStretched: true,
    isUppercase: false,
    type: "primary"
  }
];

export const getCvvInfoActions = (
  formDispatch: FormDispatch
): ButtonAction[] => [
  {
    text: "Got it",
    onClick: () =>
      formDispatch({
        type: ACTION_IS_CVV_INFO_OPEN,
        payload: { status: false }
      }),
    isStretched: true,
    isUppercase: false,
    qaLabel: "close-cvv-info"
  }
];

export const getErrorMessageActions = (
  onClick: NullaryFn<void>
): ButtonAction[] => [
  {
    text: "Close",
    onClick,
    isStretched: true,
    isUppercase: false,
    qaLabel: "close"
  }
];

export const getSuccessMessageDescription = (
  contentPaws: PawsContent,
  amount: string,
  userBalance: number
) => [
  replaceCAPIVariables(
    contentPaws.createCreditDebitCardSuccessMessage.description1
  ),
  replaceCAPIVariables(
    contentPaws.createCreditDebitCardSuccessMessage.description2,
    {
      added: `<b>${formatCurrency(+amount)}</b>`,
      balance: `<b>${formatCurrency(userBalance)}</b>`
    }
  )
];

export const getSetupEcheckActions = (
  text: string,
  onCloseCallback: NullaryFn<void>,
  formDispatch: FormDispatch,
  dispatch: ReduxDispatch,
  accountsOnFile: PaymentTypeOption[],
  isMZMToggleOn: boolean,
  mainMethods: PaymentTypeItem[],
  history: History
): ButtonAction[] => [
  {
    text,
    onClick: () => {
      onCloseCallback();
      formDispatch({
        type: ACTION_IS_FEE_INFO_OPEN,
        payload: { status: false }
      });
      redirectOnFeeFree({
        dispatch,
        paymentMethod: getLastUsedPMByPaymentType(
          accountsOnFile,
          isMZMToggleOn ? "MZM" : "ACH"
        ),
        isDesktop,
        isMZMToggleOn,
        mainMethods,
        history
      });
    },
    isStretched: true,
    isUppercase: false,
    qaLabel: "setup-echeck"
  }
];

export const getFlagWarningText = (
  amount: string,
  min: number,
  max: number,
  content: CreditDebitCardCreation
) =>
  replaceCAPIVariables(
    +amount < min
      ? content.amountInput.validations.warningMinLimit
      : content.amountInput.validations.warningMaxLimit,
    {
      amount:
        +amount < min ? `${formatCurrency(min)}` : `${formatCurrency(max)}`
    }
  );
