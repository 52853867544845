import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSubscription } from "@tvg/custom-hooks";
import { Button, Header } from "@tvg/design-system";
import { get, isEmpty, noop } from "lodash";
import { createPortal } from "react-dom";
import { openLiveChat, getUserDetails } from "@tvg/utils/liveChatUtils";
import { Store } from "redux";
import { isTvg5 } from "@tvg/utils/generalUtils";
import {
  getPawsContent,
  getMZMTimeoutContent,
  getMZMTimeoutLimit,
  getMZMTimeoutToggle,
  getPawsSkipMZMErrorModals,
  getPawsErrorCodesToSkipMZMErrorModals,
  getPawsMzmOverlayContent
} from "@tvg/sh-lib-paws/redux/selectors";
import { updateBalance } from "@tvg/shared-actions/UserActions";
import * as mediatorClassic from "@tvg/mediator-classic/src";
// @ts-ignore
import ApolloQuery from "./graphql/Subscription/transationStatus.graphql";
import MessagePanel from "../../MessagePanel";
import {
  MzmOverlayMessage,
  MzmTimeoutMessage,
  PawsContent
} from "../../../types";
import AlertMessagesModal from "../../AlertMessagesModal";
import {
  handleTransactionStatusRequest,
  successMessageInitialState,
  toggleTimeoutMessage
} from "./utils";
import {
  StatusMessages,
  TransactionDataProps,
  TransactionStatusType
} from "./types";
import { StatusOverlay, OverlayContainer } from "./styled-components";
import { isMobile, isDesktop } from "../../../utils";
import { handleRetry } from "../Deposit/methods/errorHandling";

export const TransactionStatus = ({
  gasClient,
  accountId,
  transactionId,
  onClose,
  setIsLoading,
  amount,
  type = "deposit",
  bodyChild,
  paymentType,
  isLoading,
  enableTransactionPage,
  setErrorMessage,
  errorMessage,
  showSuccessModal,
  setValue,
  currentMzmTab,
  refetchAvailableMethods,
  setCurrentMzmTab,
  currentMzmPermissions,
  setCurrentMzmPermissions
}: TransactionDataProps) => {
  const dispatch = useDispatch();
  const content = useSelector<Store, PawsContent>(getPawsContent);
  const { logged, user } = useSelector((store) => get(store, "userData", {}));
  const mzmTimeoutContent = useSelector<Store, MzmTimeoutMessage>(
    getMZMTimeoutContent
  );
  const overlay = useSelector<Store, MzmOverlayMessage>(
    getPawsMzmOverlayContent
  );
  const mzmTimeoutLimit = useSelector<Store, number>(getMZMTimeoutLimit);
  const shouldHaveTimeout = useSelector(getMZMTimeoutToggle);
  const isSkipMZMErrorModalsToggleOn = useSelector<Store, boolean>(
    getPawsSkipMZMErrorModals
  );
  const errorCodesToSkipMZMErrorModals = useSelector<Store, string[]>(
    getPawsErrorCodesToSkipMZMErrorModals
  );
  const [hasStatusOverlay, setHasStatusOverlay] = useState(false);
  const [successMessages, setSuccessMessages] = useState<StatusMessages>(
    successMessageInitialState
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [desktopOverlayText, setDesktopOverlayText] = useState("");

  const { data, subscriptionHelper, onCleanSubscription } = useSubscription(
    gasClient,
    accountId && accountId !== "" && transactionId
      ? { accountId, uuid: transactionId }
      : null,
    ApolloQuery,
    currentMzmTab?.closed
  );

  const isPermissionsRequired =
    currentMzmPermissions && currentMzmPermissions.required;

  const userDetails = getUserDetails(logged, user);

  const onCleanOverlay = () => {
    if (currentMzmTab) {
      setHasStatusOverlay(false);
      currentMzmTab.close();
    }

    if (setCurrentMzmTab) {
      setCurrentMzmTab({ closed: true, close: noop });
    }
  };

  const onClean = () => {
    if (type !== "withdraw") {
      onCleanOverlay();
    }

    onCleanSubscription();
  };

  // this fix is more for desktop when the user closes the MZM tab in the browser or goes back to TVG
  // this will give the user a warning to go back to MZM or remove the overlay
  useEffect(() => {
    let cleanTimer = 0;
    let warningTextTimer = 0;
    const checkForTabVisibility = () => {
      if (document.visibilityState === "visible" && hasStatusOverlay) {
        // If the user stays for too long on the TVG page with the MZM open show that overlay will be closed using the timer below
        warningTextTimer = setTimeout(() => {
          setDesktopOverlayText(overlay.timeout.text);
        }, 1000);

        // Give time to the user or close everything related to the MZM overlay
        cleanTimer = setTimeout(() => {
          onCleanOverlay();
        }, mzmTimeoutLimit);
      } else {
        clearTimeout(cleanTimer);
        clearTimeout(warningTextTimer);
        setDesktopOverlayText("");
      }
    };

    document.addEventListener("visibilitychange", checkForTabVisibility);
    return () => {
      clearTimeout(cleanTimer);
      clearTimeout(warningTextTimer);
      document.removeEventListener("visibilitychange", checkForTabVisibility);
    };
  }, [hasStatusOverlay]);

  // This fix is more for the desktop to help verify and close the MZM tab to remove the overlay
  useEffect(() => {
    let timer = 0;
    let checkOverLayTimer = 0;

    if (!currentMzmTab?.closed && isLoading && window !== undefined) {
      timer = setInterval(() => {
        if (window.localStorage.getItem("isMzmTabClosed") === "true") {
          onCleanOverlay();
        }
      }, mzmTimeoutLimit);
    }

    // Check if the MZM tab was closed but the loading continues from the Subscription with empty data
    // If the tab event is faster to close the overlay
    if (type !== "withdraw") {
      // Give sometime when the user returns to focus the TVG Tab to verify if it needs to show the overlay
      checkOverLayTimer = setTimeout(
        () =>
          setHasStatusOverlay(
            !currentMzmTab?.closed || !!isPermissionsRequired
          ),
        1500
      );
    }

    return () => {
      clearInterval(timer);
      clearTimeout(checkOverLayTimer);
    };
  }, [isLoading, currentMzmTab?.closed, type, isPermissionsRequired]);

  useEffect(() => {
    const transactionStatus = get(
      data,
      "transactionStatus",
      ""
    ) as unknown as TransactionStatusType;
    const status = get(subscriptionHelper, "_state", "");

    if (status !== "closed" && type !== "withdraw") {
      setIsLoading(true);
    }

    if (!isEmpty(data) && !isEmpty(transactionStatus)) {
      const balance = get(data, "transactionStatus.balance");
      if ((!isDesktop || isTvg5()) && balance) {
        dispatch(updateBalance(balance));
      } else if (balance) {
        mediatorClassic.dispatch("BALANCE_UPDATE", balance);
      }

      handleTransactionStatusRequest(
        type,
        paymentType,
        transactionStatus,
        content,
        setIsLoading,
        amount,
        setSuccessMessages,
        setShowSuccessMessage,
        setErrorMessage,
        enableTransactionPage,
        isSkipMZMErrorModalsToggleOn,
        errorCodesToSkipMZMErrorModals,
        onClose,
        refetchAvailableMethods,
        showSuccessModal,
        setValue,
        onClean
      );
    }
  }, [data, subscriptionHelper, type]);

  // Handles the timeout of the connection
  useEffect(() => {
    if (shouldHaveTimeout && isLoading && currentMzmTab?.closed) {
      let subscriptionTimeout = 0;
      // If the component is loading, it starts a timeout of X seconds but as soon as it stops loading
      // it clears the timeout
      if (isLoading) {
        clearTimeout(subscriptionTimeout);
        subscriptionTimeout = setTimeout(() => {
          setHasStatusOverlay(false);
          onClean();
          toggleTimeoutMessage(
            true,
            mzmTimeoutContent,
            setErrorMessage,
            onClose
          );
        }, mzmTimeoutLimit);
      } else {
        clearTimeout(subscriptionTimeout);
      }

      return () => {
        clearTimeout(subscriptionTimeout);
      };
    }

    return () => null;
  }, [isLoading, shouldHaveTimeout, currentMzmTab?.closed]);

  const onOpenUrl = () => {
    if (currentMzmPermissions && window) {
      const tab = window.open(currentMzmPermissions.url, "_blank");

      if (tab && setCurrentMzmTab) {
        setCurrentMzmTab(tab);
        tab.focus();

        // Go the normal overlay description
        if (setCurrentMzmPermissions) {
          setCurrentMzmPermissions({ required: false, url: "" });
        }
      }
    }
  };

  const onCreateStatusOverlay = () => {
    if (hasStatusOverlay && type !== "withdraw") {
      return createPortal(
        <>
          <StatusOverlay>
            {isPermissionsRequired ? (
              <OverlayContainer>
                <Header
                  tag="h1"
                  fontFamily="medium"
                  color="white.900"
                  mb="space-4"
                  qaLabel="qaLabel"
                >
                  {overlay.permissions.text}
                </Header>
                <Button
                  onPress={onOpenUrl}
                  qaLabel="bt-time"
                  variant="secondary"
                  mx="auto"
                >
                  {overlay.permissions.action}
                </Button>
              </OverlayContainer>
            ) : (
              <Header
                tag="h1"
                fontFamily="medium"
                color="white.900"
                qaLabel="qaLabel"
              >
                <>
                  {overlay.default.text} {desktopOverlayText}
                </>
              </Header>
            )}
          </StatusOverlay>
        </>,
        document.body
      );
    }

    return null;
  };

  return (
    <>
      {onCreateStatusOverlay()}
      {!errorMessage.isOpen && showSuccessMessage && !isLoading && (
        <MessagePanel
          title={successMessages.title}
          description={successMessages.description}
          fullPage
          variant="success"
          bodyChild={bodyChild}
          actions={[
            {
              text: "Close",
              onClick: onClose,
              isStretched: true,
              isUppercase: false,
              type: "primary"
            }
          ]}
          className="mazooma-status-message"
        />
      )}

      <AlertMessagesModal
        title={errorMessage.title}
        description={errorMessage.description}
        type={errorMessage.type || "error"}
        isOpen={errorMessage.isOpen}
        errorCode={errorMessage.errorCode}
        isCapiDefault={errorMessage.type !== "info"}
        isMobile={isMobile}
        onCloseMethod={onClose}
        onRetryMethod={handleRetry(setErrorMessage, errorMessage, userDetails)}
        onContactMethod={() => openLiveChat(userDetails)}
        retryCount={errorMessage.retryCount}
        actions={
          errorMessage?.actions || [
            {
              text: "Retry",
              onClick: handleRetry(setErrorMessage, errorMessage, userDetails),
              isStretched: true,
              isUppercase: false,
              qaLabel: "retry"
            }
          ]
        }
      />
    </>
  );
};

// @ts-ignore
export default TransactionStatus;
