import { Dispatch as ReactDispatch, SetStateAction } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { isEmpty } from "lodash";
import {
  closeDepositFundsModal,
  closeMoneyPakFundsModal
} from "@tvg/sh-lib-paws/redux/slices/depositFundsModalSlice";
import { closePaymentMethodSelectorModal } from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import { togglePMCreate } from "@tvg/sh-lib-paws/redux/slices/createPMModalSlice";
import { goToWallet } from "@tvg/utils/routeRedirectUtils";
import { events as AlchemerEvents } from "@urp/alchemer";
import { History } from "@tvg/ts-types/History";
import { Location } from "react-router-dom";
import {
  AvailablePaymentMethodsInfos,
  ErrorMessage,
  PaymentType,
  FundsModalFields
} from "../../../../types";
import {
  gtmAddPaymentMethod,
  gtmDepositClose,
  gtmDepositCreationReturn,
  gtmDepositFirstInteraction
} from "../../../../gtm";
import { refetchAvailableMethodsForQuickDeposits } from "../../common/utils";
import { RefetchAvailableMethods } from "../../common/types";
import { closeQuickDepositModal } from "../../../QuickDeposit/utils";
import { isDesktop } from "../../../../utils";

export const onMoneyPakClose = (
  dispatch: Dispatch,
  paymentType: PaymentType,
  accountId: string,
  onCloseCallback: NullaryFn<void>
) => {
  dispatch(closeMoneyPakFundsModal());
  gtmDepositClose(paymentType, false, accountId);
  onCloseCallback();
};

export const onDepositClose =
  (
    dispatch: Dispatch,
    paymentMethodSelectorModalOpen: boolean,
    paymentType: PaymentType,
    accountId: string,
    onCloseCallback: NullaryFn<void>,
    setMazoomaStatus: ReactDispatch<SetStateAction<boolean>>
  ) =>
  (shouldEraseCreateData: boolean = true) => {
    dispatch(closeDepositFundsModal(shouldEraseCreateData));
    if (
      typeof window !== "undefined" &&
      window.location.hash === "#mazoomaStatus"
    ) {
      setMazoomaStatus(false);
      sessionStorage.removeItem("transationData");
      window.history.pushState(null, document.title, window.location.pathname);
    }
    gtmDepositClose(paymentType, false, accountId);
    onCloseCallback();

    if (paymentMethodSelectorModalOpen) {
      dispatch(closePaymentMethodSelectorModal());
    }

    AlchemerEvents.closeDeposit();
  };

export const handleDepositStateReset = (
  isModalOpen: boolean,
  setValue: ReactDispatch<SetStateAction<string>>,
  setSelectedField: ReactDispatch<SetStateAction<FundsModalFields>>,
  setIsDepositHoldFundsType: ReactDispatch<SetStateAction<boolean>>,
  setCVV: ReactDispatch<SetStateAction<string>>,
  setCVVError: ReactDispatch<SetStateAction<string>>,
  setIsCVVInfoOpen: ReactDispatch<SetStateAction<boolean>>,
  setFeeValue: ReactDispatch<SetStateAction<string>>,
  setIsFeeInfoOpen: ReactDispatch<SetStateAction<boolean>>,
  setShowAmountWarning: ReactDispatch<SetStateAction<boolean>>,
  setIsLoading: ReactDispatch<SetStateAction<boolean>>,
  setEnableDepositPage: ReactDispatch<SetStateAction<boolean>>,
  setShowSuccessMessage: ReactDispatch<SetStateAction<boolean>>,
  setMazoomaRequest: ReactDispatch<SetStateAction<boolean>>,
  setMazoomaStatus: ReactDispatch<SetStateAction<boolean>>,
  forceReset?: boolean
) => {
  if (!isModalOpen || forceReset) {
    if (!forceReset) {
      setValue("");
      setSelectedField(FundsModalFields.VALUE);
    }
    setMazoomaStatus(false);
    setMazoomaRequest(false);
    setIsDepositHoldFundsType(false);
    setCVV("");
    setCVVError("");
    setIsCVVInfoOpen(false);
    setFeeValue("");
    setIsFeeInfoOpen(false);
    setShowAmountWarning(false);
    setIsLoading(false);
    setEnableDepositPage(false);
  }

  setShowSuccessMessage(false);
};

export const shouldRenderHeader = (
  isLoading: boolean,
  showQuickDepositLoading: boolean,
  showSuccessMessage: boolean,
  showMazoomaStatus: boolean,
  enableDepositPage: boolean
) => {
  if (!isDesktop && showQuickDepositLoading) return true;

  return (
    (!showQuickDepositLoading && !showSuccessMessage && !showMazoomaStatus) ||
    enableDepositPage ||
    isLoading
  );
};

export const refetchOnModalOpen =
  (
    isModalOpen: boolean,
    dispatch: Dispatch,
    accountId: string,
    errorMessageInitialState: ErrorMessage,
    isMZMToggleOn: boolean,
    isRedirectFromMZM: boolean,
    refetchAvailableMethods: RefetchAvailableMethods,
    setErrorMessage: ReactDispatch<SetStateAction<ErrorMessage>>,
    setHasError: ReactDispatch<SetStateAction<boolean>>,
    setHasLastUsed: ReactDispatch<SetStateAction<boolean>>,
    setIsLoadingQuickDeposit: ReactDispatch<SetStateAction<boolean>>,
    isQuickDepositOpen?: boolean,
    availablePaymentMethodsInfos?: AvailablePaymentMethodsInfos
  ) =>
  () => {
    if (
      isModalOpen &&
      isQuickDepositOpen &&
      !isEmpty(availablePaymentMethodsInfos)
    ) {
      refetchAvailableMethodsForQuickDeposits(
        dispatch,
        accountId,
        errorMessageInitialState,
        isMZMToggleOn,
        isRedirectFromMZM,
        refetchAvailableMethods,
        setErrorMessage,
        setHasError,
        setHasLastUsed,
        setIsLoadingQuickDeposit
      );
    }
  };

export const executeGtmOnFirstInteraction =
  (
    isModalOpen: boolean,
    setHasInteracted: ReactDispatch<SetStateAction<boolean>>,
    hasInteracted: boolean,
    accountId: string,
    paymentType: PaymentType,
    isFirstDepositDone: boolean,
    isQuickDeposit?: boolean,
    isQuickDepositOpen?: boolean
  ) =>
  () => {
    // Reset state for interaction
    if (
      (!isModalOpen || (isQuickDeposit && !isQuickDepositOpen)) &&
      hasInteracted
    )
      setHasInteracted(false);

    if (
      (isModalOpen || (isQuickDeposit && isQuickDepositOpen)) &&
      !hasInteracted
    ) {
      // On first click interaction on a deposit modal send a GTM event:
      const onDepositInteraction = () => {
        gtmDepositFirstInteraction({
          accountId,
          paymentType,
          isFirstDeposit: !isFirstDepositDone
        });

        setHasInteracted(true);
      };

      document.addEventListener("mousedown", onDepositInteraction);

      return () => {
        document.removeEventListener("mousedown", onDepositInteraction);
      };
    }

    return undefined;
  };

export const onBack =
  (
    paymentType: PaymentType,
    dispatch: Dispatch,
    paymentMethodSelectorModalOpen: boolean,
    accountId: string,
    onCloseCallback: NullaryFn<void>,
    setMazoomaStatus: ReactDispatch<SetStateAction<boolean>>
  ) =>
  () => {
    gtmDepositCreationReturn(paymentType);
    onDepositClose(
      dispatch,
      paymentMethodSelectorModalOpen,
      paymentType,
      accountId,
      onCloseCallback,
      setMazoomaStatus
    )(false);
    dispatch(togglePMCreate());
  };

export const onAddPaymentMethodClick =
  (dispatch: Dispatch, location: Location, history: History | undefined) =>
  () => {
    gtmAddPaymentMethod();
    closeQuickDepositModal(dispatch, location)();

    if (history) {
      goToWallet(history, isDesktop);
    }
  };

export const onFeeClick =
  (
    isLoading: boolean,
    setIsFeeInfoOpen: ReactDispatch<SetStateAction<boolean>>
  ) =>
  () => {
    if (!isLoading) {
      setIsFeeInfoOpen(true);
    }
  };
