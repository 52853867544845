import React, { Fragment } from "react";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { UserInfo } from "@tvg/ts-types/User";
import { getUserDetails } from "@tvg/utils/liveChatUtils";
import AlertInline from "@tvg/design-system/src/components/alertInline";
import { matchDepositPropositions } from "@tvg/sh-lib-promos-onboarding/utils/matchDepositPropositions";
import { DepositStep } from "@tvg/sh-lib-promos-onboarding/types/promoSteps";
import { PaymentType } from "@tvg/ts-types/Payment";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import PromosOnboarding from "@tvg/promos-onboarding";

import { LinkItem, OtherPaymentTab, TabContent } from "../common/types";
import { OtherPaymentsContainer, Tab } from "./styled-components";
import {
  AlertContainer,
  Link,
  LinksContainer,
  Separator
} from "../common/styled-components";
import { onLinkClick, onMessageClick, onTabClick } from "./eventHandler";

export const getTabs = (
  tabs: OtherPaymentTab[],
  setValue: UnaryFn<number, void>,
  value: number
): JSX.Element[] =>
  tabs.map((tab, index) => {
    const isActive = index === value;
    return (
      <Tab
        isActive={isActive}
        onClick={onTabClick(index, tab, setValue)}
        data-qa-label={`tab-${tab.method}-${isActive ? "enabled" : "disabled"}`}
        key={tab.method}
      >
        {tab.title}
      </Tab>
    );
  });

export const renderList = (list: string[]) => (
  <ul data-qa-label="other-payments-methods-list">
    {list.map((listItem: string, index: number) => (
      <li key={`${listItem}-${index.toString()}`}>{listItem}</li>
    ))}
  </ul>
);

export const renderLinks = (links: LinkItem[], method: PaymentType) => (
  <LinksContainer>
    {links.map((listItem: LinkItem) => (
      <Link
        key={`${listItem.text}-${listItem.url}`}
        data-qa-label="other-payments-methods-link"
        href={listItem.url}
        onClick={onLinkClick(listItem, method)}
      >
        {listItem.text}
      </Link>
    ))}
  </LinksContainer>
);

export const renderCSMessage = (
  message: string,
  logged: boolean,
  user: UserInfo
) => {
  const userDetails = getUserDetails(logged, user);

  return (
    <p data-qa-label="other-payments-methods-message">
      {message}
      <Link
        data-qa-label="other-payments-methods-message-link-support"
        href="/redirectEngine?type=messageUs"
        onClick={onMessageClick(userDetails)}
      >
        message our customer service.
      </Link>
    </p>
  );
};

export const getElement = (
  tabContent: TabContent,
  method: PaymentType,
  logged: boolean,
  user: UserInfo
) => {
  switch (tabContent.type) {
    case "text":
      return (
        <p data-qa-label="other-payments-methods-text">{tabContent.content}</p>
      );
    case "separator":
      return <Separator data-qa-label="other-payments-methods-separator" />;
    case "list":
      return renderList(tabContent.content as string[]);
    case "link":
      return renderLinks(tabContent.content as LinkItem[], method);
    case "support":
      return renderCSMessage(tabContent.content as string, logged, user);
    case "alert":
      return (
        <AlertContainer>
          <AlertInline
            message={tabContent.content as string}
            variant="warning"
            qaLabel="alert-inline"
          />
        </AlertContainer>
      );
    default:
      return <div>Item not found</div>;
  }
};

export const getPromosOnboarding: Function = (
  promosOnboarding: DepositStep,
  paymentType: string
): JSX.Element => (
  <PromosOnboarding
    placeToRender={StoryblokPlaceName.WALLET_DEPOSIT_TOP}
    isShown={matchDepositPropositions(promosOnboarding, paymentType)}
  />
);

export const getContent = (
  tabs: OtherPaymentTab[],
  activeIndex: number,
  logged: boolean,
  user: UserInfo,
  promosOnboarding?: DepositStep
): JSX.Element[] =>
  tabs.map(
    (tab, index): JSX.Element => (
      <Fragment key={tab.method}>
        {index === activeIndex &&
          promosOnboarding &&
          getPromosOnboarding(promosOnboarding, tab.method)}
        <OtherPaymentsContainer
          isActive={index === activeIndex}
          data-qa-label={`other-pm-${tab.method}-container`}
        >
          {(tab.content || []).map((content) =>
            getElement(content, tab.method, logged, user)
          )}
        </OtherPaymentsContainer>
      </Fragment>
    )
  );
