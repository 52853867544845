import React, { useState, useEffect } from "react";
import { Store } from "redux";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { openExternalLink } from "@tvg/utils/mobileUtils";
import { getUserDetails } from "@tvg/utils/liveChatUtils";
import { Icon as IconDS, buildColor, AlertInline } from "@tvg/design-system";
import {
  getDepositFundsModal,
  getPawsContent,
  getIsFirstDepositDone
} from "@tvg/sh-lib-paws/redux/selectors";
import { getAccountNumber, getBalance } from "@urp/store-selectors";
import {
  matchDepositPropositions,
  getCustomAmount
} from "@tvg/sh-lib-promos-onboarding/utils/matchDepositPropositions";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import PromosOnboarding from "@tvg/promos-onboarding";
import { fontCondensedNormal } from "@tvg/atomic-ui/_static/Typography";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { locationPin } from "@tvg/atomic-ui/_static/Icons/icons";
import parseCapiMessage, { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import formatCurrency from "@tvg/formatter/currency";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import CustomKeyboard from "@tvg/atomic-ui/_molecule/CustomKeyboard";
import { PaymentType } from "@tvg/ts-types/Payment";
import Flag from "../../Flag";
import AlertMessagesModal from "../../AlertMessagesModal";
import MessagePanel from "../../MessagePanel";
import InputField from "../common/InputField";
import Button from "../common/Button";
import MoneypakLimitsModal from "../../MoneypakLimitsModal";
import { getCAPIMessageByType, isMobile, isDesktop } from "../../../utils";
import { openLivePerson, getErrorMessageInitialState } from "../common/utils";
import { PawsContent, ErrorMessage } from "../../../types";
import {
  Container,
  Main,
  Location,
  LocationText,
  LocationLink,
  MoneyPakDescription,
  ValueContainer
} from "./styled-components";
import { MoneyPakProps } from "../common/types";
import { moneypakSubmitDeposit } from "./methods/submitMethods";
import { onMoneyPakClose } from "./methods/modalStateManagement";
import {
  clearValue,
  onMoneyPakChangeValue,
  onMoneypakClearValue
} from "./methods/valueModifiers";
import { handleCloseError, handleRetry } from "./methods/errorHandling";

const MoneyPakFundsModal = ({
  refetchAvailableMethods,
  onCloseCallback,
  promosOnboarding,
  isUserInPromoOnboarding,
  promoOnboardingStepsNumber,
  promoOnboardingSteps,
  promoOnboardingActions,
  promoOnboardingCustomLabel = () => "",
  userHasBetPlaced
}: MoneyPakProps) => {
  const dispatch = useDispatch();

  const {
    isMoneyPakModalOpen
  }: {
    isMoneyPakModalOpen: boolean;
  } = useSelector(getDepositFundsModal);

  const paymentType = PaymentType.MONEY_PAK;
  const accountId = useSelector<Store, string>(getAccountNumber);
  const userBalance = useSelector<Store, number>(getBalance);
  const content = useSelector<Store, PawsContent>(getPawsContent);
  const isFirstDepositDone = useSelector<Store, boolean>(getIsFirstDepositDone);
  const capiMessageName = getCAPIMessageByType(paymentType) || "";
  const pawsErrorMessages = useSelector((store) =>
    parseCapiMessage(store, `capi.messages.${capiMessageName}`, {})
  );
  const mpakMessages = content.deposit.MPAK;

  const [value, setValue] = useState("");
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDepositLimitsOpen, setIsDepositLimitsOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const errorMessageInitialState = getErrorMessageInitialState(
    content.paymentMessageModal.error.title,
    content.paymentMessageModal.error.description
  );

  const { logged, user } = useSelector((store) => get(store, "userData", {}));
  const userDetails = getUserDetails(logged, user);

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(
    errorMessageInitialState
  );

  useEffect(() => {
    setValue("");
    setAmount("");
    setIsLoading(false);
    setIsDepositLimitsOpen(false);
    setShowSuccessMessage(false);
  }, [isMoneyPakModalOpen]);

  useEffect(() => {
    if (errorMessage.isRetry) {
      moneypakSubmitDeposit(
        paymentType,
        accountId,
        value,
        !isFirstDepositDone,
        errorMessageInitialState.description,
        dispatch,
        setErrorMessage,
        pawsErrorMessages,
        setIsLoading,
        setAmount,
        setShowSuccessMessage,
        refetchAvailableMethods
      )();
    }
  }, [errorMessage]);

  const promoOnboardingMatchDepositProp = matchDepositPropositions(
    promosOnboarding,
    paymentType,
    +amount
  );

  const promoOnboardingCustomAmount =
    (promosOnboarding &&
      getCustomAmount(promosOnboarding, paymentType, +amount)) ||
    0;

  return (
    <>
      <ModalV2
        isOpen={isMoneyPakModalOpen}
        title={content.depositFundsModal.title}
        subtitle={`BALANCE ${formatCurrency(userBalance)}`}
        subtitleFontFamily={fontCondensedNormal}
        onClose={() =>
          onMoneyPakClose(dispatch, paymentType, accountId, onCloseCallback)
        }
        onOverlayClick={() =>
          onMoneyPakClose(dispatch, paymentType, accountId, onCloseCallback)
        }
        hasHeader={!showSuccessMessage}
        qaLabel="moneypak-funds-modal"
        animation={isMobile ? "bottom" : "fade"}
        offsetLeft={isMobile ? 0 : 12}
        offsetRight={isMobile ? 0 : 12}
        fixedWidth={isMobile ? "100%" : "375px"}
        isFullWidth={isMobile}
        offsetTop={55}
        isFullHeight={isMobile}
        hasRoundedCorners={!isMobile}
        isTitleCapitalized={false}
        isTitleCenter
        useModalHeaderV3
      >
        {() => (
          <Container isMobile={isMobile}>
            {!showSuccessMessage && (
              <>
                <PromosOnboarding
                  placeToRender={StoryblokPlaceName.WALLET_DEPOSIT_TOP}
                  isShown={promoOnboardingMatchDepositProp}
                  customMessage={promoOnboardingCustomLabel(
                    promoOnboardingCustomAmount
                  )}
                />

                <Location>
                  <Icon
                    icon={locationPin}
                    color={buildColor("blue_accent", "500")}
                    data-qa-label="moneypak-modal-location-icon"
                  />
                  <LocationText data-qa-label="moneypak-modal-location-description">
                    {mpakMessages.locationText}
                  </LocationText>
                  <LocationLink
                    onClick={() => openExternalLink(mpakMessages.locationLink)}
                    data-qa-label="moneypak-modal-location-link"
                  >
                    <IconDS
                      name="popout"
                      size="s"
                      lineColor="blue_accent.300"
                      backgroundColor="white.000"
                    />
                  </LocationLink>
                </Location>
                <Main>
                  <ValueContainer>
                    {!!mpakMessages.greenDotPolicyMessage && (
                      <AlertInline
                        message={mpakMessages.greenDotPolicyMessage}
                        qaLabel="moneypak-modal-green-policy"
                        variant="warning"
                        mb={50}
                      />
                    )}
                    <InputField
                      value={value}
                      onChangeValue={onMoneyPakChangeValue(
                        isLoading,
                        setValue,
                        value
                      )}
                      className="code-field"
                      placeholder="000 - 000 - 0000 - 0000"
                      type="big"
                      isDisabled={isLoading}
                      hasFocus={isMoneyPakModalOpen}
                      isDesktop={isDesktop}
                      maxValidLimit={23}
                    />
                    <Flag
                      qaLabel="deposit-limits"
                      className="flag"
                      text={mpakMessages.limitsButton}
                      variant="fee"
                      {...(!isLoading && {
                        onClick: () => setIsDepositLimitsOpen(true)
                      })}
                    />
                    <MoneyPakDescription>
                      {mpakMessages.descriptionSteps.map((step, index) => (
                        <p
                          key={`description-${index + 1}`}
                          data-qa-label={`moneypak-modal-instruction-${
                            index + 1
                          }`}
                        >
                          {step}
                        </p>
                      ))}
                    </MoneyPakDescription>
                  </ValueContainer>

                  {!isLoading && !isDesktop && (
                    <CustomKeyboard
                      className="custom-keyboard"
                      showPeriod={false}
                      showMoneyPills={false}
                      onDelete={onMoneypakClearValue(
                        isLoading,
                        setValue,
                        value
                      )}
                      onChangeValue={onMoneyPakChangeValue(
                        isLoading,
                        setValue,
                        value
                      )}
                    />
                  )}

                  <Button
                    text="Redeem and authorize"
                    onClick={moneypakSubmitDeposit(
                      paymentType,
                      accountId,
                      value,
                      !isFirstDepositDone,
                      errorMessageInitialState.description,
                      dispatch,
                      setErrorMessage,
                      pawsErrorMessages,
                      setIsLoading,
                      setAmount,
                      setShowSuccessMessage,
                      refetchAvailableMethods
                    )}
                    isLoading={isLoading}
                    hasError={clearValue(value).length !== 14}
                    qaLabel="mpak-submit-button"
                  />
                </Main>
              </>
            )}
            {showSuccessMessage && (
              <MessagePanel
                title={content.transactionDepositSuccessMessage.title}
                description={[
                  replaceCAPIVariables(
                    content.transactionDepositSuccessMessage.description1,
                    {
                      added: `<b>${formatCurrency(+amount)}</b>`,
                      balance: `<b>${formatCurrency(userBalance)}</b>`
                    }
                  ),
                  replaceCAPIVariables(
                    content.transactionDepositSuccessMessage.description2,
                    {
                      added: `<b>${formatCurrency(+amount)}</b>`,
                      balance: `<b>${formatCurrency(userBalance)}</b>`
                    }
                  )
                ]}
                variant="success"
                actions={[
                  {
                    text: "Close",
                    onClick: () =>
                      onMoneyPakClose(
                        dispatch,
                        paymentType,
                        accountId,
                        onCloseCallback
                      ),
                    isStretched: true,
                    isUppercase: false,
                    type: "primary"
                  }
                ]}
                className="success-message"
                isUserInPromoOnboarding={isUserInPromoOnboarding}
                promoOnboardingStepsNumber={promoOnboardingStepsNumber}
                promoOnboardingSteps={promoOnboardingSteps}
                promoOnboardingActions={
                  promoOnboardingActions
                    ? promoOnboardingActions(() =>
                        onMoneyPakClose(
                          dispatch,
                          paymentType,
                          accountId,
                          onCloseCallback
                        )
                      )
                    : []
                }
                bonusAmount={promoOnboardingCustomAmount}
                hasMatchingProposition={promoOnboardingMatchDepositProp}
                userHasBetPlaced={userHasBetPlaced}
              />
            )}
          </Container>
        )}
      </ModalV2>

      <MoneypakLimitsModal
        title={mpakMessages.depositLimits.title}
        description={mpakMessages.depositLimits.description}
        tableData={mpakMessages.depositLimits.limits}
        buttonText={mpakMessages.depositLimits.buttonText}
        isOpen={isDepositLimitsOpen}
        onClose={() => setIsDepositLimitsOpen(false)}
      />

      {errorMessage.isOpen && (
        <AlertMessagesModal
          type={errorMessage.type || "error"}
          isOpen={errorMessage.isOpen}
          errorCode={errorMessage.errorCode}
          isCapiDefault
          closeButtonQaLabel="mpak-error-modal-closeBtn"
          isMobile={isMobile}
          onCloseMethod={handleCloseError(setErrorMessage, errorMessage)}
          onRetryMethod={handleRetry(
            setErrorMessage,
            errorMessage,
            userDetails
          )}
          onContactMethod={() =>
            openLivePerson(
              userDetails,
              errorMessage && +errorMessage.errorCode === 112703
            )
          }
          retryCount={errorMessage.retryCount}
          actions={[
            {
              text: "Close",
              onClick: handleCloseError(setErrorMessage, errorMessage),
              isStretched: true,
              isUppercase: false,
              qaLabel: "close"
            }
          ]}
        />
      )}
    </>
  );
};

export default MoneyPakFundsModal;
