import React, { useEffect, useState, useMemo } from "react";
import { Store } from "redux";
import { get, noop } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import tvgConf from "@tvg/conf";
import { openLiveChat, getUserDetails } from "@tvg/utils/liveChatUtils";
import { events as AlchemerEvents } from "@urp/alchemer";
import {
  getWireTransferWithdrawModalOpen,
  getWireTransferAutomationToggle,
  getPaymentMethodSelectorModal,
  getWithdrawFundsModal,
  getSelectedPaymentMethod,
  getPawsLimits,
  getAccountsOnFileMethods,
  getPawsContent
} from "@tvg/sh-lib-paws/redux/selectors";
import { getAccountNumber, getBalance } from "@urp/store-selectors";
import {
  closeWithdrawFundsModal,
  setIsWireTransferFundsModalOpen
} from "@tvg/sh-lib-paws/redux/slices/withdrawFundsModalSlice";
import { getWithdrawalLimits } from "@tvg/sh-lib-paws/hooks/withdrawalLimits";
import { fontCondensedNormal } from "@tvg/atomic-ui/_static/Typography";
import parseCapiMessage, { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import formatCurrency from "@tvg/formatter/currency";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import PaymentMethodSelector from "@tvg/atomic-ui/_templates/PaymentMethodSelector";
import CustomKeyboard from "@tvg/atomic-ui/_molecule/CustomKeyboard";
import MoneyPills from "@tvg/atomic-ui/_molecule/MoneyPills";
import { buildColor, Button as DSButton } from "@tvg/design-system";
import ApolloContext from "@tvg/utils/apolloContext";
import { PaypalLogo } from "@tvg/atomic-ui/_static/Icons/paws";
import { PaymentType as PaymentTypeEnum } from "@tvg/ts-types/Payment";
import {
  getCAPIMessageByType,
  getSuccessWithdrawalActions,
  isDesktop,
  isMobile
} from "@tvg/wallet/src/utils";
import { getMazoomaSubscriptionToggle } from "@tvg/sh-lib-paws/redux/toggles";
import { gtmWithdrawValidationError } from "../../../gtm";
import WireTransferWithdraw from "./wireTransfer";
import Flag from "../../Flag";
import AlertMessagesModal from "../../AlertMessagesModal";
import MessagePanel from "../../MessagePanel";
import InputValue from "../common/InputValue";
import Button from "../common/Button";
import Summary from "../common/Summary";
import {
  PaymentTypeOption,
  PaymentType,
  FundsAmountInputChangingFrom,
  PaymentTypeItem,
  ErrorMessage
} from "../../../types";
import {
  Container,
  Main,
  ValueContainer,
  PaymentMethodTitle,
  CancelButtonWrapper
} from "./styled-components";
import { AddressConfirmationModal } from "../../AddressConfirmationModal";
import TransactionStatus from "../TransationStatus";
import { getErrorMessageInitialState, onAddPeriod } from "../common/utils";
import type { WithdrawalProps } from "./types";
import { WithdrawalContent } from "./types";
import { getSucessMessageBodyChild } from "./components/successMessageBodyChild";
import { ModalSubtitle } from "./components/modalSubtitle";
import { InfoMessage } from "./components/infoMessage";
import { InfoAlert } from "./components/infoAlert";
import {
  onWithdrawChangeValue,
  onWithdrawClearValue,
  parseMaxWithdrawAmountToFloat
} from "./methods/valueModifiers";
import {
  hasMorePlayableBalance,
  hasNoBalance,
  hasOnlyPlayableBalance
} from "../common/balanceUtils";
import { onSubmitWithdraw } from "./methods/submitMethods";
import {
  handleWithdrawCloseError,
  handleWithdrawRetry,
  insufficientFundsErrorMessage
} from "./methods/errorHandling";
import {
  shouldRenderHeader,
  onWithdrawClose
} from "./methods/modalStateManagement";
import { MessageType } from "../../AddressConfirmationModal/types";

const WithdrawFundsModal = ({
  onCloseCallback,
  refetchAvailableMethods,
  otherMethods,
  isQuickWithdrawOpen,
  isQuickWithdraw
}: WithdrawalProps) => {
  const dispatch = useDispatch();
  const { isModalOpen }: { isModalOpen: boolean } = useSelector(
    getWithdrawFundsModal
  );
  const { id: selectedPaymentMethodId, paymentType }: PaymentTypeOption =
    useSelector(getSelectedPaymentMethod);
  const accountId: string = useSelector(getAccountNumber);
  const userBalance = useSelector<Store, number>(getBalance);
  const capiMessageName = paymentType ? getCAPIMessageByType(paymentType) : "";
  const pawsErrorMessages = useSelector((store) =>
    parseCapiMessage(store, `capi.messages.${capiMessageName}`, {})
  );
  const paymentMethodSelectorModalOpen: boolean = useSelector(
    getPaymentMethodSelectorModal
  );
  const enableMazoomaSubscription: boolean = useSelector(
    getMazoomaSubscriptionToggle
  );
  const limits = useSelector(getPawsLimits);
  const content: WithdrawalContent = useSelector(getPawsContent);

  const isWithdrawModalOpen = isQuickWithdraw
    ? isQuickWithdrawOpen
    : isModalOpen;
  const minWithdrawAmount = get(limits, `${paymentType}.withdrawal.min`, 2);

  const { cancel: wireTransferWithdrawContentCancelButton } =
    content.wireTransferWithdrawContent.confirmation;

  const isWireTransferModalOpen = useSelector(getWireTransferWithdrawModalOpen);

  const isWireTransferAutomationToggleOn = useSelector<Store, boolean>(
    getWireTransferAutomationToggle
  );

  const [value, setValue] = useState("");
  const [transactionID, setTransactionID] = useState<string>("");
  const [showAmountWarning, setShowAmountWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessagePmType, setSuccessMessagePmType] =
    useState<PaymentType>(paymentType);
  const [addressConfirmationOpen, setAddressConfirmationState] =
    useState(false);
  const [withdrawalLimit, setWithdrawalLimit] = useState(0);
  const [showInfoMessage, setShowInfoMessage] = useState(false);
  const [enableDepositPage, setEnableDepositPage] = useState<boolean>(false);

  const capiMaxWithdrawAmount = get(
    limits,
    `${paymentType}.withdrawal.max`,
    userBalance
  );

  const errorMessageInitialState = getErrorMessageInitialState(
    content.paymentMessageModal.error.title,
    content.paymentMessageModal.error.description
  );

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(
    errorMessageInitialState
  );
  const accountsOnFile: PaymentTypeItem[] = useSelector(
    getAccountsOnFileMethods
  );

  const tvg = tvgConf();
  const { logged, user } = useSelector((store) => get(store, "userData", {}));
  const userDetails = getUserDetails(logged, user);

  const showMZMTransactionStatus =
    showSuccessMessage &&
    successMessagePmType === PaymentTypeEnum.MAZOOMA &&
    enableMazoomaSubscription;

  useEffect(() => {
    if (accountId)
      getWithdrawalLimits(accountId, setWithdrawalLimit, capiMaxWithdrawAmount);
  }, [accountId, setWithdrawalLimit, capiMaxWithdrawAmount]);

  useEffect(() => {
    const pBalance = parseMaxWithdrawAmountToFloat(capiMaxWithdrawAmount);

    if (
      hasNoBalance(withdrawalLimit, pBalance) ||
      hasOnlyPlayableBalance(withdrawalLimit, pBalance) ||
      (accountsOnFile.length === 0 && withdrawalLimit === 0)
    ) {
      setShowInfoMessage(true);
    } else {
      setShowInfoMessage(false);
    }
  }, [withdrawalLimit, capiMaxWithdrawAmount, accountsOnFile]);

  useEffect(() => {
    handleStateReset();
  }, [isWithdrawModalOpen]);

  useEffect(() => {
    if (errorMessage.isRetry) {
      onSubmitWithdraw({
        value,
        minWithdrawAmount,
        maxWithdrawAmount: withdrawalLimit,
        paymentType,
        selectedPaymentMethodId,
        accountId,
        setIsLoading,
        setShowAmountWarning,
        dispatch,
        setShowSuccessMessage,
        setErrorMessage,
        pawsErrorMessages,
        setWithdrawalLimit,
        capiMaxWithdrawAmount,
        refetchAvailableMethods,
        setTransactionID,
        setEnableDepositPage,
        setSuccessMessagePmType
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    const validation =
      (+value > +withdrawalLimit || +value < +minWithdrawAmount) && !!value;
    const timer = validation ? 3000 : 0;
    const debounceWarning = setTimeout(() => {
      setShowAmountWarning(validation);
      if (validation) {
        gtmWithdrawValidationError({
          paymentType,
          accountID: accountId,
          errorMessage: insufficientFundsErrorMessage({
            minWithdrawAmount,
            value,
            userBalance: withdrawalLimit
          })
        });
      }
    }, timer);

    return () => clearTimeout(debounceWarning);
  }, [value]);

  const paymentTypeSuccessMessage = get(
    content,
    `${successMessagePmType}WithdrawSuccessMessage`,
    ""
  );

  const defaultSuccessMessage = get(
    content,
    "transactionWithdrawSuccessMessage",
    ""
  );

  const successMessage = paymentTypeSuccessMessage || defaultSuccessMessage;

  const handleStateReset = (forceReset?: boolean) => {
    if (!forceReset) {
      setValue("");
      setShowAmountWarning(false);
    }
    setIsLoading(false);
    setShowSuccessMessage(false);
    setAddressConfirmationState(false);
    setEnableDepositPage(false);
  };

  // TO-DO: this hardcoded value should be removed when there's backend
  // for the WireTransfer automation flow available
  const feeValue = "1";
  const showPaymentMethodSelector =
    !isWireTransferAutomationToggleOn ||
    (isWireTransferAutomationToggleOn &&
      paymentType !== PaymentTypeEnum.WIRE_TRANSFER);
  const showFee =
    isWireTransferAutomationToggleOn &&
    paymentType === PaymentTypeEnum.WIRE_TRANSFER &&
    !showAmountWarning &&
    +feeValue > 0;

  const renderedModalContent = useMemo(
    () =>
      (!showSuccessMessage || enableDepositPage || isLoading) && (
        <Main>
          <ValueContainer>
            <InputValue
              isLoading={isLoading}
              showAmountWarning={showAmountWarning}
              value={value}
              isDesktop={isDesktop}
              onChangeValue={onWithdrawChangeValue(isLoading, value, setValue)}
              hasFocus={!!isWithdrawModalOpen}
            />
            {showAmountWarning && (
              <Flag
                qaLabel="warning-value"
                className="flag"
                text={insufficientFundsErrorMessage({
                  minWithdrawAmount,
                  value,
                  userBalance: withdrawalLimit
                })}
              />
            )}
            {showFee && (
              <Flag
                qaLabel="fee-value"
                className="flag"
                text={`+ ${formatCurrency(+feeValue)} FEE`}
                variant="fee"
                showIcon={false}
              />
            )}
          </ValueContainer>

          {isDesktop && (
            <MoneyPills
              disabled={isLoading}
              className="moneyPills"
              onChangeValue={(keyValue: string) =>
                onWithdrawChangeValue(
                  isLoading,
                  value,
                  setValue
                )(keyValue, "moneyPills")
              }
            />
          )}

          {isQuickWithdraw && (
            <PaymentMethodTitle>Select Payment Method</PaymentMethodTitle>
          )}
          {showPaymentMethodSelector && (
            <PaymentMethodSelector
              className="payment-selector"
              device={tvg.device}
              isModal={isMobile}
              isDisabled={!isQuickWithdrawOpen || isLoading}
              onCloseDepositModal={onCloseCallback}
              onChangePaymentMethod={() => handleStateReset(true)}
              otherMethods={otherMethods}
            />
          )}
          {isWireTransferAutomationToggleOn &&
            !isMobile &&
            paymentType === PaymentTypeEnum.WIRE_TRANSFER && (
              <Summary
                value={value}
                fee={feeValue}
                className="summary"
                showBorderBottom
              />
            )}
          {!isLoading && !isDesktop && (
            <CustomKeyboard
              className="custom-keyboard"
              onDelete={onWithdrawClearValue(setValue)}
              onPeriod={onAddPeriod(value, setValue)}
              onChangeValue={(
                keyValue: number,
                from: FundsAmountInputChangingFrom
              ) =>
                onWithdrawChangeValue(
                  isLoading,
                  value,
                  setValue
                )(
                  keyValue,
                  from === "mobile" ? "mobileAmountField" : "moneyPills"
                )
              }
            />
          )}

          <Button
            text={
              paymentType === PaymentTypeEnum.PAYPAL ? (
                <PaypalLogo />
              ) : (
                `Authorize ${formatCurrency(+value)} transaction`
              )
            }
            onClick={() =>
              paymentType !== PaymentTypeEnum.CHECK
                ? onSubmitWithdraw({
                    value,
                    minWithdrawAmount,
                    maxWithdrawAmount: withdrawalLimit,
                    paymentType,
                    selectedPaymentMethodId,
                    accountId,
                    setIsLoading,
                    setShowAmountWarning,
                    dispatch,
                    setShowSuccessMessage,
                    setErrorMessage,
                    pawsErrorMessages,
                    setWithdrawalLimit,
                    capiMaxWithdrawAmount,
                    refetchAvailableMethods,
                    setTransactionID,
                    setEnableDepositPage,
                    setSuccessMessagePmType
                  })
                : setAddressConfirmationState(true)
            }
            isLoading={isLoading}
            hasError={+value <= 0 || showAmountWarning}
            bgColor={
              paymentType === PaymentTypeEnum.PAYPAL
                ? buildColor("yellow", isLoading ? "300" : "500")
                : undefined
            }
          />
          {isWireTransferAutomationToggleOn &&
            paymentType === PaymentTypeEnum.WIRE_TRANSFER && (
              <CancelButtonWrapper>
                <DSButton
                  qaLabel="wiretransfer-cancel-withdraw"
                  variant={wireTransferWithdrawContentCancelButton.variant}
                  onPress={() => dispatch(closeWithdrawFundsModal())}
                >
                  {wireTransferWithdrawContentCancelButton.text}
                </DSButton>
              </CancelButtonWrapper>
            )}
        </Main>
      ),
    [
      isLoading,
      showAmountWarning,
      value,
      isDesktop,
      withdrawalLimit,
      tvg,
      isMobile,
      minWithdrawAmount,
      paymentType,
      selectedPaymentMethodId,
      accountId,
      pawsErrorMessages,
      capiMaxWithdrawAmount,
      showSuccessMessage,
      isQuickWithdrawOpen,
      enableDepositPage,
      otherMethods
    ]
  );

  if (isWireTransferModalOpen && !isQuickWithdraw) {
    return (
      <WireTransferWithdraw
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        subtitle={
          <ModalSubtitle
            description={content.withdrawFundsModal.description}
            capiMaxWithdrawAmount={capiMaxWithdrawAmount}
            setShowInfoMessage={setShowInfoMessage}
            withdrawalLimit={withdrawalLimit}
            accountsOnFile={accountsOnFile}
            isLoading={isLoading}
          />
        }
      />
    );
  }

  return (
    <>
      <ModalV2
        isOpen={isWithdrawModalOpen}
        title={
          isWireTransferAutomationToggleOn &&
          paymentType === PaymentTypeEnum.WIRE_TRANSFER
            ? "Wire Transfer"
            : content.withdrawFundsModal.title
        }
        subtitle={
          <ModalSubtitle
            description={content.withdrawFundsModal.description}
            capiMaxWithdrawAmount={capiMaxWithdrawAmount}
            setShowInfoMessage={setShowInfoMessage}
            withdrawalLimit={withdrawalLimit}
            accountsOnFile={accountsOnFile}
            isLoading={isLoading}
          />
        }
        subtitleFontFamily={fontCondensedNormal}
        onClose={onWithdrawClose(
          paymentType,
          accountId,
          dispatch,
          onCloseCallback,
          errorMessageInitialState,
          paymentMethodSelectorModalOpen,
          setValue,
          setShowSuccessMessage,
          setEnableDepositPage,
          setErrorMessage
        )}
        onOverlayClick={onWithdrawClose(
          paymentType,
          accountId,
          dispatch,
          onCloseCallback,
          errorMessageInitialState,
          paymentMethodSelectorModalOpen,
          setValue,
          setShowSuccessMessage,
          setEnableDepositPage,
          setErrorMessage
        )}
        hasHeader={shouldRenderHeader(
          withdrawalLimit,
          parseMaxWithdrawAmountToFloat(capiMaxWithdrawAmount),
          showSuccessMessage,
          isLoading,
          enableDepositPage,
          accountsOnFile
        )}
        qaLabel="funds-modal"
        animation={isMobile ? "bottom" : "fade"}
        offsetLeft={isMobile ? 0 : 12}
        offsetRight={isMobile ? 0 : 12}
        fixedWidth={isMobile ? "100%" : "375px"}
        isFullWidth={isMobile}
        offsetTop={55}
        isFullHeight={isMobile}
        hasRoundedCorners={!isMobile}
        isTitleCapitalized={false}
        isTitleCenter
        useModalHeaderV3
        {...(isWireTransferAutomationToggleOn &&
          paymentType === PaymentTypeEnum.WIRE_TRANSFER && {
            onBack: () => {
              dispatch(closeWithdrawFundsModal());
              dispatch(setIsWireTransferFundsModalOpen(true));
            }
          })}
      >
        {() => (
          <Container isMobile={isMobile}>
            {!showSuccessMessage && !isLoading && (
              <InfoMessage
                capiMaxWithdrawAmount={parseMaxWithdrawAmountToFloat(
                  capiMaxWithdrawAmount
                )}
                onClose={onWithdrawClose(
                  paymentType,
                  accountId,
                  dispatch,
                  onCloseCallback,
                  errorMessageInitialState,
                  paymentMethodSelectorModalOpen,
                  setValue,
                  setShowSuccessMessage,
                  setEnableDepositPage,
                  setErrorMessage
                )}
                showInfoMessage={showInfoMessage}
                withdrawalLimit={withdrawalLimit}
                accountsOnFile={accountsOnFile}
                isQuickWithdraw={!!isQuickWithdrawOpen}
              />
            )}

            {!isLoading && showInfoMessage
              ? hasMorePlayableBalance(
                  withdrawalLimit,
                  parseMaxWithdrawAmountToFloat(capiMaxWithdrawAmount),
                  accountsOnFile
                ) && renderedModalContent
              : renderedModalContent}
            {paymentType === PaymentTypeEnum.CHECK && addressConfirmationOpen && (
              <AddressConfirmationModal
                isOpen={addressConfirmationOpen}
                onCloseMethod={() => setAddressConfirmationState(false)}
                onCancelCb={() => setAddressConfirmationState(false)}
                paymentType={paymentType}
                accountID={accountId}
                messageType={MessageType.WITHDRAW}
                onSubmit={(address) => {
                  setAddressConfirmationState(false);
                  onSubmitWithdraw({
                    value,
                    minWithdrawAmount,
                    maxWithdrawAmount: withdrawalLimit,
                    paymentType,
                    selectedPaymentMethodId,
                    accountId,
                    setIsLoading,
                    setShowAmountWarning,
                    dispatch,
                    setShowSuccessMessage,
                    setErrorMessage,
                    pawsErrorMessages,
                    requestBody: {
                      address
                    },
                    setWithdrawalLimit,
                    capiMaxWithdrawAmount,
                    refetchAvailableMethods,
                    setTransactionID,
                    setEnableDepositPage,
                    setSuccessMessagePmType
                  });
                }}
              />
            )}
            {showMZMTransactionStatus && (
              <ApolloContext.Consumer>
                {(clients: {
                  gasClient: ApolloClient<NormalizedCacheObject>;
                }) => (
                  <TransactionStatus
                    type="withdraw"
                    bodyChild={getSucessMessageBodyChild(
                      userBalance,
                      withdrawalLimit
                    )}
                    enableTransactionPage={setEnableDepositPage}
                    paymentType={paymentType}
                    amount={+value}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    onClose={onWithdrawClose(
                      paymentType,
                      accountId,
                      dispatch,
                      onCloseCallback,
                      errorMessageInitialState,
                      paymentMethodSelectorModalOpen,
                      setValue,
                      setShowSuccessMessage,
                      setEnableDepositPage,
                      setErrorMessage
                    )}
                    accountId={accountId}
                    transactionId={transactionID}
                    gasClient={clients.gasClient}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    refetchAvailableMethods={refetchAvailableMethods}
                    currentMzmTab={{ closed: true, close: noop }}
                    showSuccessModal
                  />
                )}
              </ApolloContext.Consumer>
            )}
            {showSuccessMessage &&
              successMessagePmType !== PaymentTypeEnum.MAZOOMA && (
                <MessagePanel
                  title={successMessage.title}
                  bodyChild={getSucessMessageBodyChild(
                    userBalance,
                    withdrawalLimit
                  )}
                  description={[
                    replaceCAPIVariables(successMessage.description1, {
                      removed: `<b>${formatCurrency(+value)}</b>`
                    }),
                    replaceCAPIVariables(successMessage.description2, {
                      balance: `<b>${formatCurrency(userBalance)}</b>`
                    })
                  ]}
                  variant="success"
                  actions={getSuccessWithdrawalActions(() => {
                    onWithdrawClose(
                      paymentType,
                      accountId,
                      dispatch,
                      onCloseCallback,
                      errorMessageInitialState,
                      paymentMethodSelectorModalOpen,
                      setValue,
                      setShowSuccessMessage,
                      setEnableDepositPage,
                      setErrorMessage
                    )();
                    AlchemerEvents.closeWithdrawal();
                  })}
                  className="success-message"
                />
              )}
          </Container>
        )}
      </ModalV2>
      {accountsOnFile.length > 0 && (
        <InfoAlert
          capiMaxWithdrawAmount={parseMaxWithdrawAmountToFloat(
            capiMaxWithdrawAmount
          )}
          setShowInfoMessage={setShowInfoMessage}
          showInfoMessage={showInfoMessage && !!isWithdrawModalOpen}
          withdrawalLimit={withdrawalLimit}
          accountsOnFile={accountsOnFile}
        />
      )}
      {/*
          MZM Transation Status has its own instance of AlertMessages
          In order to prevent further issues, this specific instance shouldn't be available
          if the other one is instantiated
      */}
      {!showMZMTransactionStatus && (
        <AlertMessagesModal
          type={errorMessage.type || "error"}
          isOpen={errorMessage.isOpen}
          errorCode={errorMessage.errorCode}
          isCapiDefault
          isMobile={isMobile}
          onCloseMethod={handleWithdrawCloseError(setErrorMessage)}
          onRetryMethod={handleWithdrawRetry(
            errorMessage,
            userDetails,
            setErrorMessage
          )}
          onContactMethod={() => openLiveChat(userDetails)}
          retryCount={errorMessage.retryCount}
          actions={[
            {
              text: "Close",
              onClick: handleWithdrawCloseError(setErrorMessage),
              isStretched: true,
              isUppercase: false,
              qaLabel: "close"
            }
          ]}
        />
      )}
    </>
  );
};

export default WithdrawFundsModal;
