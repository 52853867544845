import React, { Dispatch, SetStateAction } from "react";
import { PaymentType } from "@tvg/ts-types/Payment";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { AddressConfirmationModal } from "../../AddressConfirmationModal";
import { MessageType } from "../../AddressConfirmationModal/types";
import { FormDispatch } from "./types";
import Summary from "../../FundsModal/common/Summary";
import { resetAllFields } from "./methods";
import { ErrorMessage } from "../../../types";

export const renderAddressConfirmationModal = (
  isOpen: boolean,
  formDispatch: FormDispatch,
  setAddressConfirmationState: Dispatch<SetStateAction<boolean>>,
  setSelectedField: Dispatch<SetStateAction<string>>,
  setVisitedFields: Dispatch<SetStateAction<Set<string>>>,
  setErrorMessage: Dispatch<SetStateAction<ErrorMessage>>,
  errorMessageInitialState: ErrorMessage,
  accountId: string,
  onSubmit: NullaryFn<void>
) => (
  <AddressConfirmationModal
    isOpen={isOpen}
    messageType={MessageType.BILLING}
    onCancelCb={() =>
      resetAllFields(
        false,
        setAddressConfirmationState,
        setSelectedField,
        setVisitedFields,
        formDispatch,
        setErrorMessage,
        errorMessageInitialState
      )
    }
    onCloseMethod={() => {
      setAddressConfirmationState(false);
    }}
    paymentType={PaymentType.CREDIT_DEBIT_CARD}
    accountID={accountId}
    onSubmit={() => {
      setAddressConfirmationState(false);
      onSubmit();
    }}
  />
);

export const renderSummary = (amount: string, feeValue: string) => (
  <Summary value={amount} fee={feeValue} className="summary" />
);
