import { tvgColorPalette } from "../common";
import { ColorTokens } from "./types";

const core = {
  transparentBlack: tvgColorPalette.tint.transparentBlack,
  blackTint005: tvgColorPalette.black["005"],
  blackTint010: tvgColorPalette.black["000"],
  blackTint020: tvgColorPalette.black["100"],
  blackTint030: tvgColorPalette.black["200"],
  blackTint040: tvgColorPalette.black["300"],
  blackTint050: tvgColorPalette.black["400"],
  blackTint060: tvgColorPalette.black["500"],
  blackTint070: tvgColorPalette.black["600"],
  blackTint080: tvgColorPalette.black["700"],
  blackTint090: tvgColorPalette.black["800"],
  transparentWhite: tvgColorPalette.tint.transparentWhite,
  whiteTint005: tvgColorPalette.white["005"],
  whiteTint010: tvgColorPalette.white["000"],
  whiteTint020: tvgColorPalette.white["100"],
  whiteTint030: tvgColorPalette.white["200"],
  whiteTint040: tvgColorPalette.white["300"],
  whiteTint050: tvgColorPalette.white["400"],
  whiteTint060: tvgColorPalette.white["500"],
  whiteTint070: tvgColorPalette.white["600"],
  whiteTint080: tvgColorPalette.white["700"],
  whiteTint090: tvgColorPalette.white["800"]
};

const content = {
  strong: tvgColorPalette.grey["900"],
  default: tvgColorPalette.grey["900"],
  subtle: tvgColorPalette.grey["800"],
  subtle2: tvgColorPalette.grey["700"],
  subtle3: tvgColorPalette.grey["500"],
  disabled: tvgColorPalette.black["100"],
  onLight: tvgColorPalette.grey["900"],
  onDark: tvgColorPalette.white["900"],
  link: tvgColorPalette.blue_accent["500"],
  linkActive: tvgColorPalette.blue_accent["700"],
  brandPrimary: tvgColorPalette.blue_accent["500"],
  brandSecondary: tvgColorPalette.grey["900"],
  info: tvgColorPalette.blue_accent["700"],
  positive: tvgColorPalette.green["800"],
  negative: tvgColorPalette.red["700"],
  warning: tvgColorPalette.orange["900"],
  alert: tvgColorPalette.yellow["900"],
  neutral: tvgColorPalette.grey["800"],
  mtp: tvgColorPalette.blue["400"],
  loadingDefault: tvgColorPalette.blue["000"],
  loadingInverse: tvgColorPalette.white["000"]
};

const component = {
  // TODO: REVISION TAGS
  tag: {
    offer: {
      background: tvgColorPalette.yellow["500"],
      content: tvgColorPalette.black["800"]
    },
    offer_subtle: {
      background: tvgColorPalette.yellow["100"],
      content: tvgColorPalette.black["900"]
    },
    "1stpick": {
      background: tvgColorPalette.green["100"],
      content: tvgColorPalette.green["800"]
    },
    "2ndpick": {
      background: tvgColorPalette.blue["000"],
      content: tvgColorPalette.grey["800"]
    },
    "3rdpick": {
      background: tvgColorPalette.orange["100"],
      content: tvgColorPalette.orange["900"]
    },
    regular_subtle: {
      background: tvgColorPalette.blue["000"],
      content: tvgColorPalette.grey["800"]
    }
  },
  button: {
    primary: {
      content: {
        base: tvgColorPalette.white["900"],
        hover: tvgColorPalette.white["900"],
        active: tvgColorPalette.white["900"],
        disabled: tvgColorPalette.black["200"]
      },
      background: {
        base: tvgColorPalette.green["500"],
        hover: tvgColorPalette.green["600"],
        active: tvgColorPalette.green["700"],
        disabled: tvgColorPalette.blue["000"]
      }
    },
    secondary: {
      content: {
        base: tvgColorPalette.white["900"],
        hover: tvgColorPalette.white["900"],
        active: tvgColorPalette.white["900"],
        disabled: tvgColorPalette.black["200"]
      },
      background: {
        base: tvgColorPalette.blue_accent["500"],
        hover: tvgColorPalette.blue_accent["600"],
        active: tvgColorPalette.blue_accent["700"],
        disabled: tvgColorPalette.blue["000"]
      }
    },
    tertiary: {
      content: {
        base: tvgColorPalette.grey["900"],
        hover: tvgColorPalette.grey["900"],
        active: tvgColorPalette.grey["900"],
        disabled: tvgColorPalette.black["200"]
      },
      background: {
        base: tvgColorPalette.white["900"],
        hover: tvgColorPalette.blue_accent["000"],
        active: tvgColorPalette.blue_accent["100"],
        disabled: tvgColorPalette.blue["000"]
      },
      border: {
        base: tvgColorPalette.blue["100"],
        hover: tvgColorPalette.blue["100"],
        active: tvgColorPalette.blue["100"],
        disabled: tvgColorPalette.black["100"]
      }
    },
    destructive: {
      content: {
        base: tvgColorPalette.white["900"],
        hover: tvgColorPalette.white["900"],
        active: tvgColorPalette.white["900"],
        disabled: tvgColorPalette.black["200"]
      },
      background: {
        base: tvgColorPalette.red["500"],
        hover: tvgColorPalette.red["600"],
        active: tvgColorPalette.red["700"],
        disabled: tvgColorPalette.blue["000"]
      }
    },
    buttonLink: {
      content: {
        base: tvgColorPalette.blue_accent["500"],
        hover: tvgColorPalette.blue_accent["500"],
        active: tvgColorPalette.blue_accent["500"],
        disabled: tvgColorPalette.black["200"]
      },
      background: {
        base: "transparent",
        hover: tvgColorPalette.blue_accent["100"],
        active: tvgColorPalette.blue_accent["200"],
        disabled: tvgColorPalette.blue["000"]
      },
      border: {
        disabled: "transparent"
      }
    },
    betting: {
      content: {
        base: tvgColorPalette.white["900"],
        hover: tvgColorPalette.white["900"],
        active: tvgColorPalette.white["900"],
        disabled: tvgColorPalette.black["200"]
      },
      background: {
        base: tvgColorPalette.green["500"],
        hover: tvgColorPalette.green["600"],
        active: tvgColorPalette.green["700"],
        disabled: tvgColorPalette.blue["000"]
      }
    },
    promo: {
      content: {
        base: tvgColorPalette.grey["900"],
        hover: tvgColorPalette.grey["900"],
        active: tvgColorPalette.grey["900"],
        disabled: tvgColorPalette.black["200"]
      },
      background: {
        base: tvgColorPalette.yellow["500"],
        hover: tvgColorPalette.yellow["600"],
        active: tvgColorPalette.yellow["700"],
        disabled: tvgColorPalette.blue["000"]
      }
    },
    secondaryDark: {
      content: {
        base: tvgColorPalette.white["900"],
        hover: tvgColorPalette.white["900"],
        active: tvgColorPalette.white["900"],
        disabled: tvgColorPalette.white["200"]
      },
      background: {
        base: tvgColorPalette.white["000"],
        hover: tvgColorPalette.white["100"],
        active: tvgColorPalette.white["200"],
        disabled: tvgColorPalette.white["000"]
      }
    },
    transparent: {
      content: {
        base: tvgColorPalette.white["900"],
        hover: tvgColorPalette.white["900"],
        active: tvgColorPalette.white["900"],
        disabled: tvgColorPalette.white["200"]
      },
      background: {
        base: tvgColorPalette.white["000"],
        hover: tvgColorPalette.white["100"],
        active: tvgColorPalette.white["200"],
        disabled: tvgColorPalette.white["000"]
      },
      border: {
        base: "transparent",
        hover: "transparent",
        active: "transparent",
        disabled: "transparent"
      }
    }
  },
  notification: {
    info: {
      background: tvgColorPalette.blue_accent["600"],
      backgroundSubtle: tvgColorPalette.blue_accent["000"],
      border: tvgColorPalette.blue_accent["200"],
      content: tvgColorPalette.blue_accent["700"]
    },
    positive: {
      background: tvgColorPalette.green["600"],
      backgroundSubtle: tvgColorPalette.green["100"],
      border: tvgColorPalette.green["300"],
      content: tvgColorPalette.green["800"]
    },
    negative: {
      background: tvgColorPalette.red["500"],
      backgroundSubtle: tvgColorPalette.red["000"],
      border: tvgColorPalette.red["200"],
      content: tvgColorPalette.red["700"]
    },
    warning: {
      background: tvgColorPalette.orange["500"],
      backgroundSubtle: tvgColorPalette.orange["000"],
      border: tvgColorPalette.orange["200"],
      content: tvgColorPalette.orange["900"]
    },
    promo: {
      background: tvgColorPalette.yellow["500"],
      backgroundSubtle: tvgColorPalette.yellow["000"],
      border: tvgColorPalette.yellow["200"],
      content: tvgColorPalette.yellow["900"]
    },
    geocomply: {
      backgroundVerification: tvgColorPalette.blue_accent["600"],
      backgroundSuccess: tvgColorPalette.green["600"]
    }
  },
  betslip: {
    background: tvgColorPalette.blue["800"],
    backgroundExpanded: tvgColorPalette.blue["900"]
  },
  activeGroupedFilterCell: {
    content: tvgColorPalette.blue_accent["700"],
    background: tvgColorPalette.blue_accent["100"],
    border: tvgColorPalette.blue_accent["500"]
  },
  input: {
    base: tvgColorPalette.grey["300"],
    surface: tvgColorPalette.white["900"],
    hover: tvgColorPalette.blue_accent["100"],
    pressed: tvgColorPalette.blue_accent["200"],
    active: tvgColorPalette.blue_accent["500"],
    selected: tvgColorPalette.blue_accent["700"],
    disabled: tvgColorPalette.blue["000"],
    border: tvgColorPalette.blue["100"]
  }
};

const background = {
  base: tvgColorPalette.blue["000"],
  surface: tvgColorPalette.white["900"],
  layer: tvgColorPalette.blue_accent["000"],
  accent: tvgColorPalette.grey["000"],
  hover: tvgColorPalette.blue_accent["100"],
  fadeEnd: "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)",
  fadeBottom:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)",
  primary: tvgColorPalette.blue_accent["500"],
  secondary: tvgColorPalette.blue["900"],
  promotional: tvgColorPalette.blue["900"],
  info: tvgColorPalette.blue_accent["600"],
  infoSubtle: tvgColorPalette.blue_accent["100"],
  positive: tvgColorPalette.green["600"],
  positiveSubtle: tvgColorPalette.green["100"],
  negative: tvgColorPalette.red["500"],
  negativeSubtle: tvgColorPalette.red["000"],
  warning: tvgColorPalette.orange["500"],
  warningSubtle: tvgColorPalette.orange["000"],
  alert: tvgColorPalette.yellow["500"],
  alertSubtle: tvgColorPalette.yellow["000"],
  neutral: tvgColorPalette.grey["600"],
  neutralSubtle: tvgColorPalette.grey["000"]
};

const lhnHeader = {
  content: {
    strong: tvgColorPalette.blue["000"],
    default: tvgColorPalette.blue["200"],
    subtle: tvgColorPalette.white["500"],
    brandSecondary: tvgColorPalette.blue["300"],
    positive: tvgColorPalette.green["400"],
    negative: tvgColorPalette.red["400"]
  },
  border: {
    default: tvgColorPalette.blue["800"],
    inverse: tvgColorPalette.blue["600"]
  },
  selectedcell: {
    border: tvgColorPalette.blue["600"],
    background: tvgColorPalette.blue["800"]
  }
};

const border = {
  default: tvgColorPalette.blue["100"],
  subtle: tvgColorPalette.blue["000"],
  inverse: tvgColorPalette.white["900"],
  brand: tvgColorPalette.blue["100"],
  info: tvgColorPalette.blue_accent["200"],
  positive: tvgColorPalette.green["300"],
  negative: tvgColorPalette.red["200"],
  warning: tvgColorPalette.orange["200"],
  alert: tvgColorPalette.yellow["200"]
};

export const myBets = {
  open: {
    border: "transparent",
    background: "transparent",
    dotColor: tvgColorPalette.grey["600"],
    textColor: tvgColorPalette.grey["800"],
    iconColor: tvgColorPalette.grey["600"],
    trackingColor: tvgColorPalette.grey["300"]
  },
  raceOff: {
    border: tvgColorPalette.grey["800"],
    background: tvgColorPalette.white["100"],
    textColor: tvgColorPalette.grey["900"],
    iconColor: tvgColorPalette.grey["800"],
    dotColor: tvgColorPalette.grey["800"],
    trackingColor: tvgColorPalette.grey["300"]
  },
  unknown: {
    border: tvgColorPalette.grey["800"],
    background: tvgColorPalette.white["100"],
    textColor: tvgColorPalette.grey["900"],
    iconColor: tvgColorPalette.grey["800"],
    dotColor: tvgColorPalette.grey["800"],
    trackingColor: tvgColorPalette.grey["300"]
  },
  win: {
    border: tvgColorPalette.green["700"],
    background: tvgColorPalette.green["100"],
    textColor: tvgColorPalette.green["700"],
    iconColor: tvgColorPalette.green["700"],
    trackingColor: tvgColorPalette.green["300"]
  },
  lost: {
    border: tvgColorPalette.red["700"],
    background: tvgColorPalette.red["100"],
    textColor: tvgColorPalette.red["700"],
    iconColor: tvgColorPalette.red["700"],
    trackingColor: tvgColorPalette.red["300"]
  },
  attention: {
    border: tvgColorPalette.grey["900"],
    background: tvgColorPalette.orange["400"],
    textColor: tvgColorPalette.orange["700"],
    iconColor: tvgColorPalette.grey["900"],
    trackingColor: tvgColorPalette.orange["300"]
  }
};

export const tvgColorTokens: ColorTokens = {
  core,
  component,
  content,
  background,
  lhnHeader,
  border,
  myBets,
  groupedfiltercell: {
    background: {
      default: "transparent",
      hover: tvgColorPalette.blue_accent["000"],
      pressed: tvgColorPalette.blue_accent["100"],
      active: tvgColorPalette.blue_accent["100"]
    },
    border: {
      default: "transparent",
      hover: tvgColorPalette.blue["100"],
      pressed: tvgColorPalette.blue["100"],
      active: tvgColorPalette.blue_accent["500"]
    },
    content: {
      default: tvgColorPalette.grey["900"],
      hover: tvgColorPalette.grey["900"],
      pressed: tvgColorPalette.grey["900"],
      active: tvgColorPalette.blue_accent["700"]
    }
  },
  notification: {
    info: {
      background: tvgColorPalette.blue_accent["600"],
      backgroundSubtle: tvgColorPalette.blue_accent["000"],
      subtle: tvgColorPalette.blue_accent["700"],
      content: tvgColorPalette.white["900"],
      border: tvgColorPalette.blue_accent["200"]
    },
    positive: {
      background: tvgColorPalette.green["600"],
      backgroundSubtle: tvgColorPalette.green["100"],
      subtle: tvgColorPalette.green["800"],
      content: tvgColorPalette.white["900"],
      border: tvgColorPalette.green["300"]
    },
    negative: {
      background: tvgColorPalette.red["500"],
      backgroundSubtle: tvgColorPalette.red["000"],
      subtle: tvgColorPalette.red["700"],
      content: tvgColorPalette.white["900"],
      border: tvgColorPalette.red["200"]
    },
    warning: {
      background: tvgColorPalette.orange["500"],
      backgroundSubtle: tvgColorPalette.orange["000"],
      subtle: tvgColorPalette.orange["900"],
      content: tvgColorPalette.white["900"],
      border: tvgColorPalette.orange["200"]
    },
    promo: {
      background: tvgColorPalette.yellow["500"],
      backgroundSubtle: tvgColorPalette.yellow["000"],
      subtle: tvgColorPalette.yellow["900"],
      content: tvgColorPalette.grey["900"],
      border: tvgColorPalette.yellow["200"]
    }
  }
} as const;
